import {
  getProvider, getProviderLocationReviews, getProviderLogos, getBios
} from '_api_/nex.health.api'
import {
  providerConstants, faqConstants, providerLogosConstants
} from '_utils_/constants'
import moment from 'moment'

export const mutationTypes = {
  clearState: 'clearState',
  setProviderInfo: 'setProviderInfo',
  setProviderLogos: 'setProviderLogos',
  setStoredDirection: 'setStoredDirection'
}

export const state = {
  provider: { ...providerConstants },
  providerLogos: { ...providerLogosConstants },
  storedDirection: {}
}
export const getters = {
  provider: ({provider}) =>{
    return provider
  },
  direction: ({provider}) => {
    const location = provider.locations[0]

    return {
      name: location.name,
      address: location.streetAddress,
      city: location.city,
      state: location.state,
      zipCode: location.zipCode,
      phoneNumber : location.phoneNumber,
      latitude: location.latitude,
      longitude: location.longitude
    }
  },
  location: ({provider}) => {
    return provider.locations[0]
  },
  workingHours: ({provider}) => {
    const daysOfWeek = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
    const workingHours = provider.locations[0].workingHours
    const workDaysData = []
    daysOfWeek.forEach(dayOfWeek => {
        let currentDay = workingHours.filter(workingHour =>
            workingHour.day == dayOfWeek)[0]
        workDaysData.push({
            day: dayOfWeek,
            beginTime: currentDay ? moment.parseZone(currentDay.beginTime ).format("h:mm a") : "",
            endTime: currentDay ? moment.parseZone(currentDay.endTime).format("h:mm a") : "Closed",
            closed: currentDay ? false : true,
            dash: currentDay ? "-" : ""
        })
    });
    return workDaysData
  },
  reviews: ({provider}) => {
    return provider.reviews
  },
  practiceBio: ({provider}) => {
    return provider.practiceBio
  },
  providerBio: ({provider}) => {
    return provider.providerBio
  },
  providerLogos: ({providerLogos}) => {
    return providerLogos
  },
  faqs: ({provider}) => {
    faqConstants.forEach(faq => {
      if (faq.response.includes('{practiceName}')) {
        faq.response = faq.response.replace('{practiceName}', provider.locations[0].name)
      }
    });
    return faqConstants
  }
}
export const mutations = {
  [mutationTypes.setProviderInfo](state, payload) {
    state.provider = { ...state.provider, ...payload }
  },
  [mutationTypes.clearState](state) {
    state.provider =  {
      provider: { }
    }
  },
  [mutationTypes.setStoredDirection](state, { getters }){
    const direction = getters.direction
    state.storedDirection = JSON.parse(JSON.stringify(direction))
  },
  [mutationTypes.setProviderLogos](state, payload){
    state.providerLogos = payload
  }
}

export const actions = {
  async requestProvider({ commit, dispatch, getters },{ id, vulcanStoreNumber, locationUUID }) {
    try {
      dispatch('AppProcess/setLoadingStatus', true, { root: true })
      const response = await getProvider(id, vulcanStoreNumber, locationUUID)
      const provider = JSON.parse(JSON.stringify(response))

      const reviewsResponse = await getProviderLocationReviews(provider.locations[0].id)
      const providerReviews = JSON.parse(JSON.stringify(reviewsResponse))
      provider.reviews = providerReviews.reviews

      const bioResponse = await getBios(provider.id)
      const practiceBio = JSON.parse(JSON.stringify(bioResponse))
      if (practiceBio.locationBio != null) {
          provider.practiceBio = practiceBio.locationBio
        } else {
          provider.practiceBio = practiceBio.practiceBio
      }
      provider.providerBio = practiceBio.providerBio

      commit(mutationTypes.setProviderInfo, provider)
      commit(mutationTypes.setStoredDirection, { getters })
    } finally {
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
    }
  },
  async requestProviderLogos({ commit, dispatch }, providerId){
    try {
      dispatch('AppProcess/setLoadingStatus', true, { root: true })
      const response = await getProviderLogos(providerId)
      const providerLogos = JSON.parse(JSON.stringify(response))

      commit(mutationTypes.setProviderLogos, providerLogos);
    } finally {
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
