export const mutationTypes = {
  setLoadingStatus: 'setLoadingStatus'
}

export const state = {
  loading: false,
  loadingCount: 0
}

export const mutations = {
  [mutationTypes.setLoadingStatus](state, payload) {
    if (payload) {
        state.loadingCount++;
    } else if (state.loadingCount > 0) { // don't let loadingCount go negative
        state.loadingCount--;
    }
    state.loading = state.loadingCount > 0;
    }
}

export const actions = {
  setLoadingStatus({ commit }, payload) {
    commit(mutationTypes.setLoadingStatus, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
