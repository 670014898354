var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("div", { attrs: { id: "nav" } }, [_c("NavBar")], 1),
      _c("main", [_c("router-view")], 1),
      _c("BaseFooter", {
        staticClass: "base-footer",
        attrs: {
          "footer-logo": _vm.footerLogo,
          "social-icons": _vm.socialIcons,
          "footer-columns": _vm.footerNavigationColumns,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }