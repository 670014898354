<template>
    <div
        class="column is-flex is-flex-direction-column mb-5-mobile-only"
        :class="{
            'is-flex-shrink-0 is-flex-grow-2': isFirst,
            'is-flex-grow-1': !isFirst
        }"
    >
        <h2 class="title is-6 mb-3">{{ navHeading }}</h2>
        <ul v-if="!loading">
            <BaseNavItem
                v-for="nav in navItems"
                :key="nav.sys.id"
                :display-name="nav.fields.navItemDisplayName"
                :link="nav.fields.navItemLink"
                :target="nav.fields.navItemLinkTarget"
                :phone-number-display="nav.fields.phoneNumberDisplay"
                :phone-number-configuration="
                    nav.fields.phoneNumberConfiguration
                "
                :nav-item-alternate-function="
                    nav.fields.navItemAlternateFunction
                "
                @alternateNavEvent="fireAccessibe"
            />
        </ul>
        <div
            v-if="isSocial && isSocial.length > 0"
            class="column is-full social-icons"
        >
            <BaseSocialCollection
                v-for="social in isSocial"
                :key="social.sys.id"
                :collection-id="social.sys.id"
            />
        </div>
    </div>
</template>

<style
    scoped
    src="../../node_modules/@smiledirectclub/bulma-based-components/dist/sdc-base.css"
></style>

<script>
const BaseNavItem = () => import("./BaseNavItem");
const BaseSocialCollection = () => import("./BaseSocialCollection");

export default {
    name: "BaseNavCollection",
    components: {
        BaseNavItem,
        BaseSocialCollection
    },
    props: {
        isFirst: {
            type: Boolean,
            required: true
        },
        navHeading: {
            type: String,
            required: true
        },
        navItems: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: null
        };
    },
    computed: {
        isSocial() {
            return this.navItems.filter(item => {
                return (
                    item.sys.contentType.sys.id === "subCollectionSocialTokens"
                );
            });
        }
    },
    methods: {
        fireAccessibe() {
            this.$emit("openAccessibe");
        }
    }
};
</script>
