var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "navbar",
      attrs: {
        role: "navigation",
        id: "main-navbar",
        "aria-label": "main navigation",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "navbar-brand is-flex is-align-items-center mr-auto mt-2 mb-2",
        },
        [
          _c(
            "a",
            { attrs: { href: _vm.sdcUrl, target: "_blank", rel: "noopener" } },
            [
              _c("img", {
                staticClass: "is-size-1",
                attrs: {
                  src: require("../assets/sdc-logo.svg"),
                  alt: "sdc-logo",
                },
              }),
            ]
          ),
          _c(
            "a",
            {
              staticClass: "navbar-burger burger",
              attrs: {
                role: "button",
                "aria-label": "menu",
                "aria-expanded": "false",
              },
              on: { click: _vm.toggleNavbar },
            },
            [
              _c("span", { attrs: { "aria-hidden": "true" } }),
              _c("span", { attrs: { "aria-hidden": "true" } }),
              _c("span", { attrs: { "aria-hidden": "true" } }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "navbar-menu",
          class: { "is-active": _vm.isNavbarActive },
          attrs: { id: "nav-collapse" },
        },
        [
          _c("div", { staticClass: "navbar-start" }, [
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: {
                  href: _vm.GetUrl("how_it_works/"),
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v(" How It Works ")]
            ),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: {
                  href: _vm.GetUrl("results/"),
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v(" Results ")]
            ),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: {
                  href: _vm.GetUrl("pricing/"),
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v(" Pricing ")]
            ),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: {
                  href: _vm.GetUrl("insurance/"),
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v(" Insurance ")]
            ),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: {
                  href: _vm.GetUrl("smileshops/"),
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v(" Locations ")]
            ),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: {
                  href: _vm.GetUrl("accessories/"),
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v(" Accessories ")]
            ),
          ]),
          _vm._m(0),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-end is-hidden-mobile" }, [
      _c("div", { staticClass: "navbar-item" }, [
        _c("div", { staticClass: "dentalpartner-text inline gray" }, [
          _c("img", {
            staticClass: "inline",
            attrs: {
              src: require("../assets/tooth-icon.svg"),
              alt: "tooth-icon",
            },
          }),
          _vm._v(" Dental Partner "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }