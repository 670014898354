import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "./theme/theme.scss";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import ContentfulVue from "contentful-vue";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from '@datadog/browser-rum';
import VueMeta from 'vue-meta'
import ComponentLibrary from 'sdc-component-library';
import 'sdc-component-library/dist/sdc.css';

import * as GmapVue from 'gmap-vue'

if (process.env.NODE_ENV !== "development") {
    datadogLogs.init({
        clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
        service: process.env.VUE_APP_DATADOG_SERVICE,
        site: process.env.VUE_APP_DATADOG_SITE,
        version: process.env.PACKAGE_VERSION,
        env: process.env.NODE_ENV,
        forwardErrorsToLogs: true
    });

    datadogRum.init({
        applicationId: process.env.VUE_APP_DATADOG_RUM_APPLICATION_ID,
        clientToken: process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN,
        service: process.env.VUE_APP_DATADOG_SERVICE,
        site: process.env.VUE_APP_DATADOG_SITE,
        version: process.env.PACKAGE_VERSION,
        env: process.env.NODE_ENV,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input"
    });

    datadogRum.startSessionReplayRecording();
}

const options = {
    timeout: 7000
};

Vue.use(ContentfulVue, {
    space: process.env.VUE_APP_CONTENTFUL_SPACE,
    accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN
});

Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAP_KEY,
        libraries: 'places',
    },
    installComponents: true
})

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(Toast, options);
Vue.use(VueMeta)
Vue.use(ComponentLibrary);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
