/* eslint-disable no-console */
import {
    providerProxyInstance as api,
    providerProxyNon404Instance as apiNon404
} from "./sdc.config.api";

/**
 * Log an error details if env === dev
 *
 * @param {Object} {method, error} - name of method / error object
 */
/* istanbul ignore next */
const devLogger = (method, error) => {
    if (process.env.NODE_ENV === "development")
        console.log(`[${method}] Error: `, error);
};

export async function handleApiGetCall(url, methodName, use404 = false) {
    try {
        const response = await (use404 ? apiNon404.get(url) : api.get(url));
        return response.data;
    } catch (error) {
        devLogger(methodName, error);
        throw error;
    }
}
