import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=24d0f063&scoped=true&"
import script from "./BaseFooter.vue?vue&type=script&lang=js&"
export * from "./BaseFooter.vue?vue&type=script&lang=js&"
import style0 from "../../node_modules/@smiledirectclub/bulma-based-components/dist/sdc-base.css?vue&type=style&index=0&id=24d0f063&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d0f063",
  null
  
)

export default component.exports