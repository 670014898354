import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// Note: to use modules into componets, use the filename capitalized
// e.g: Module1
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: 'vuex_storedAppointment',
      paths: ['Appointment.storedAppointment']
    }),
    createPersistedState({
      storage: window.sessionStorage,
      key: 'vuex_patient',
      paths: ['Appointment.patient']
    }),
    createPersistedState({
      storage: window.sessionStorage,
      key: 'vuex_storedDirection',
      paths: ['Provider.storedDirection']
    })
  ],
  modules
})
