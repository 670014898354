<template>
    <nav
        class="navbar"
        role="navigation"
        id="main-navbar"
        aria-label="main navigation"
    >
        <div class="navbar-brand is-flex is-align-items-center mr-auto mt-2 mb-2">
            <a :href="sdcUrl" target="_blank" rel="noopener">
                <img
                    src="../assets/sdc-logo.svg"
                    alt="sdc-logo"
                    class="is-size-1"
                />
            </a>
            <a
                role="button"
                class="navbar-burger burger"
                aria-label="menu"
                aria-expanded="false"
                @click="toggleNavbar"
            >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div
            id="nav-collapse"
            class="navbar-menu"
            :class="{ 'is-active': isNavbarActive }"
        >
            <div class="navbar-start">
                <a
                    :href="GetUrl('how_it_works/')"
                    target="_blank"
                    rel="noopener"
                    class="navbar-item"
                >
                    How It Works
                </a>
                <a
                    :href="GetUrl('results/')"
                    target="_blank"
                    rel="noopener"
                    class="navbar-item"
                >
                    Results
                </a>
                <a
                    :href="GetUrl('pricing/')"
                    target="_blank"
                    rel="noopener"
                    class="navbar-item"
                >
                    Pricing
                </a>
                <a
                    :href="GetUrl('insurance/')"
                    target="_blank"
                    rel="noopener"
                    class="navbar-item"
                >
                    Insurance
                </a>
                <a
                    :href="GetUrl('smileshops/')"
                    target="_blank"
                    rel="noopener"
                    class="navbar-item"
                >
                    Locations
                </a>
                <a
                    :href="GetUrl('accessories/')"
                    target="_blank"
                    rel="noopener"
                    class="navbar-item"
                >
                    Accessories
                </a>
            </div>

            <div class="navbar-end is-hidden-mobile">
                <div class="navbar-item">
                    <div class="dentalpartner-text inline gray">
                        <img
                            class="inline"
                            src="../assets/tooth-icon.svg"
                            alt="tooth-icon"
                        />
                        Dental Partner
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    name: "NavBar",
    components: {},
    data() {
        return {
            sdcUrl: process.env.VUE_APP_SDC_ECOMMERCE_BASE_URL,
            isNavbarActive: false
        };
    },
    methods: {
        GetUrl(ref) {
            return `${this.sdcUrl}${ref}`;
        },
        toggleNavbar() {
            this.isNavbarActive = !this.isNavbarActive;
        }
    }
};
</script>

<style lang="scss">
@import "../theme/components/_navbar.scss";
</style>
