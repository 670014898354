
/* eslint-disable no-console */
import { providerProxyInstance as api } from "./sdc.config.api";
import { handleApiGetCall } from "./sdc.api.helpers";
import { datadogLogs } from "@datadog/browser-logs";

/**
 * Log an error details if env === dev
 *
 * @param {Object} {method, error} - name of method / error object
 */
/* istanbul ignore next */
const devLogger = (method, error) => {
  if (process.env.NODE_ENV === 'development')
    console.log(`[${method}] Error: `, error)
}

/**
 * Get provider info
 *
 * @param {String} id
 *
 * @returns {Object} {cases, metadata}
 * @prop {Array} cases
 * @prop {Object} metadata
 */
const getProvider = async (id, vulcanStoreNumber, locationUUID) => {
  var url='';
  url = id ? `?providerId=${id}` : (vulcanStoreNumber? `?vulcanStoreNumber=${vulcanStoreNumber}` : `?locationUUID=${locationUUID}`)
  return await handleApiGetCall(`v1/provider${url}`, "getProvider");
}

const getProviderLocationReviews = async (locationId) => {
  return await handleApiGetCall(
      `v1/provider/location/${locationId}/reviews`,
      "getProviderLocationReviews",
      true
  );
}

const getProviderLogos = async (providerId) => {
  return await handleApiGetCall(
      `v1/provider/${providerId}/assets`,
      "getProviderLogos",
      true
  );
}

const getBios = async (id) => {
  return await handleApiGetCall(
      `v1/provider/${id}/bio`,
      "getBios",
      true
  );
}

const getAppointmentSlots = async (providerId, locationId, date, days, appointmentType) => {
  let startDate = date.toISOString().slice(0, 10);
  var appointmentTypeParam = (appointmentType ? `&appointmentType=${appointmentType}`:``);
  return await handleApiGetCall(
      `/v1/appointment/slots/${providerId}/${locationId}?startDate=${startDate}&days=${days}${appointmentTypeParam}`,
      "getAppointmentSlots"
  );
}

const bookAppointment = async (createAppointmentRequest, isMinor) =>{
  try{
    const response = await api.post(`/v1/appointment/${isMinor === true ? 'minor/' : ''}`,
      createAppointmentRequest)
      return response
    } catch (error) {

    if (error.response.data.code === 400
      && error.response.data.message.includes('Time slot is not available')){
        var createAppointmentRequestObject = JSON.parse(createAppointmentRequest)
        datadogLogs.logger.error('Booking error - Time Slot conflict', {providerId: `${createAppointmentRequestObject.providerId}`,
        locationId: `${createAppointmentRequestObject.locationId}`, startTime: `${createAppointmentRequestObject.startTime}`})
        error.response.data.reload = true
        return error.response
      }
    devLogger('bookAppointment', error)
    throw error
  }
}

export {
  getProvider,
  getProviderLocationReviews,
  getProviderLogos,
  getAppointmentSlots,
  getBios,
  bookAppointment
}
