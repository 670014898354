import { render, staticRenderFns } from "./BaseNavCollection.vue?vue&type=template&id=6b650de1&scoped=true&"
import script from "./BaseNavCollection.vue?vue&type=script&lang=js&"
export * from "./BaseNavCollection.vue?vue&type=script&lang=js&"
import style0 from "../../node_modules/@smiledirectclub/bulma-based-components/dist/sdc-base.css?vue&type=style&index=0&id=6b650de1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b650de1",
  null
  
)

export default component.exports