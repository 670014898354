import { bookAppointment } from "_api_/nex.health.api";
import { getAppointmentSlots } from "_api_/nex.health.api";
import { bookedInfoConstants } from '_utils_/constants'
import moment from "moment";
import { datadogLogs } from "@datadog/browser-logs";
import { appointmentTypes } from '_utils_/constants'

export const mutationTypes = {
    clearAppointmentSlotsState: "clearAppointmentSlotsState",
    clearBookedInfoState: "clearBookedInfoState",
    clearGuardianBookedInfoState: "clearGuardianBookedInfoState",
    clearPatientAdditionalInfoState: "clearPatientAdditionalInfoState",
    clearPatientState:"clearPatientState",
    clearAppointmentState: "clearAppointmentState",
    setAppointment: "setBookedAppointment",
    setAppointmentSlots: "setAppointmentSlots",
    incrementAppointmentVersionState: "incrementAppointmentVersionState"
};

export const state = {
    appointmentSlots: [],
    appointmentSlotsVersion: 0,
    bookedInfo: { ...bookedInfoConstants },
    guardianBookedInfo: { ...bookedInfoConstants },
    appointment: {},
    storedAppointment: {},
    patient: {}
};

export const mutations = {
    [mutationTypes.setAppointment](state, data) {
        state.appointment = data.appointment;
        state.storedAppointment = JSON.parse(JSON.stringify(data.appointment))
        state.patient = data.patient
    },
    [mutationTypes.clearBookedInfoState](state) {
        state.bookedInfo = { ...bookedInfoConstants }
        state.guardianBookedInfo = { ...bookedInfoConstants }
    },
    [mutationTypes.clearGuardianBookedInfoState](state) {
        state.guardianBookedInfo = { ...bookedInfoConstants }
    },
    [mutationTypes.clearPatientAdditionalInfoState](state) {
        state.bookedInfo.email = ''
        state.bookedInfo.phoneNumber = ''
        state.bookedInfo.gender = ''
    },
    [mutationTypes.clearPatientState](state) {
        state.patient = {}
    },
    [mutationTypes.clearAppointmentState](state) {
        state.appointment = {}
        state.storedAppointment = {}
    },
    [mutationTypes.setAppointmentSlots](state, appointmentSlots) {
        state.appointmentSlots = appointmentSlots;
    },
    [mutationTypes.clearAppointmentSlotsState](state) {
        state.appointmentSlots = {
            appointmentSlots: []
        };
    },
    [mutationTypes.incrementAppointmentVersionState](state) {
        state.appointmentSlotsVersion += 1;
    }
};

export const actions = {
    async clearGuardianBookedInfo({ commit }){
        commit(mutationTypes.clearGuardianBookedInfoState)
    },
    async clearPatientAdditionalInfo({ commit }){
        commit(mutationTypes.clearPatientAdditionalInfoState)
    },
    async clearPatient({ commit }){
        commit(mutationTypes.clearPatientState)
    },
    async bookAppointmentSlot({ commit, dispatch, state },
        { locationId, selectedAppointmentTime, providerId, isMinor,
            smsConsent, contactKey, providerName, practiceName,
            utmSource, utmMedium, utmCampaign, utmTerm, utmContent, appointmentType }
    ) {
        try {
            dispatch('AppProcess/setLoadingStatus', true, { root: true })

            let booking = {}
            if (!isMinor){
                booking = JSON.parse(JSON.stringify(state.bookedInfo))
            }
            else {
                booking = JSON.parse(JSON.stringify(state.guardianBookedInfo))
                booking.minorPatient =
                    JSON.parse(JSON.stringify(state.bookedInfo, (key, value) => {
                        if (value !== null && value !== '') return value
                      }))
            }
            booking.locationId = locationId
            booking.smsConsent = smsConsent
            booking.startTime = selectedAppointmentTime.time
            booking.operatoryId = selectedAppointmentTime.operatoryId
            booking.providerId = providerId
            booking.contactKey = contactKey
            booking.phoneNumber = booking.phoneNumber.replace(/[^\d.]+/g, "")
            booking.providerName = providerName
            booking.practiceName = practiceName
            booking.utmSource = utmSource
            booking.utmMedium = utmMedium
            booking.utmCampaign = utmCampaign
            booking.utmTerm = utmTerm
            booking.utmContent = utmContent
            booking.appointmentType = await getAppointmentType(appointmentType);

            const response = await bookAppointment(JSON.stringify(booking), isMinor);
            const bookedAppointment = JSON.parse(JSON.stringify(response.data));

            if (bookedAppointment.reload) {
                commit(mutationTypes.clearBookedInfoState);
                commit(mutationTypes.clearGuardianBookedInfoState);
                commit(mutationTypes.clearAppointmentSlotsState);
                commit(mutationTypes.incrementAppointmentVersionState)
            }
            else {
                commit(mutationTypes.setAppointment, bookedAppointment);
                datadogLogs.logger.info('Successful booking', {providerId: `${response.data.appointment.providerId}`, startTime: `${response.data.appointment.startTime}`, statusCode: `${response.status}`})
                dispatch('AppProcess/setLoadingStatus', false, { root: true })
            }
        } catch (error) {
            commit(mutationTypes.clearAppointmentState);
            dispatch('AppProcess/setLoadingStatus', false, { root: true })
        }
    },
    clearBooked({ commit }) {
        commit(mutationTypes.clearBookedInfoState);
    },
    async fetchAppointmentSlots({ commit, dispatch, rootGetters },
        { providerId, isBooking, locationId, appointmentType, availabilityWindowInDays }
    ) {
        try {
            let transformedAppointmentSlots = [];
            if(isBooking) {
                dispatch('AppProcess/setLoadingStatus', true, { root: true })
                let workingHours = rootGetters['Provider/workingHours']
                var date = new Date();
                var days = availabilityWindowInDays;
                appointmentType = await getAppointmentType(appointmentType);
                const response = await getAppointmentSlots(providerId, locationId, date, days, appointmentType);
                const appointmentSlots = JSON.parse(JSON.stringify(response));
                let firstAppointmentSlot = appointmentSlots[0];
                let daysOfWeek = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
                let completeDaysOfWeek = ['Sunday', 'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
                for (let day = 0; day < days; day++) {
                    let currentFilterDate =  moment(date).format('YYYY-MM-DD')
                    let times = firstAppointmentSlot.slots.filter(slot => slot.time.slice(0, 10) === currentFilterDate)
                    let finalTimes = []
                    times.forEach(time =>{
                        finalTimes.push({
                            time: time.time,
                            timeToShow: moment.parseZone(time.time).format("h:mm a"),
                            operatoryId:time.operatoryId
                        })
                    });
                    let workingHour = workingHours.find(workingHour => workingHour.day === completeDaysOfWeek[date.getDay()])
                    transformedAppointmentSlots.push({
                        date: currentFilterDate,
                        dayOfWeek: daysOfWeek[date.getDay()],
                        day: date.getDate(),
                        available: finalTimes.length > 0,
                        note: workingHour.closed
                            ? "Closed"
                            : finalTimes.length > 0
                                ? day===0
                                    ? "Today"
                                    : day === 1
                                        ? "Tomorrow"
                                        : " "
                                : "Booked",
                        times: finalTimes
                    });
                    date.setDate(date.getDate() + 1)
                }
            }
            commit(mutationTypes.setAppointmentSlots, transformedAppointmentSlots);
        } catch (error) {
            commit(mutationTypes.clearAppointmentSlotsState);
        } finally {
            dispatch('AppProcess/setLoadingStatus', false, { root: true })
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};

export async function getAppointmentType(input) {
    // if(input === "check-in" || input === "care-plus-initial")
    //     return input
    // else return "initial"

    if(appointmentTypes.includes(input))
        return input
    else return "initial"
}
