<template>
    <div id="app">
        <div id="nav">
            <NavBar />
        </div>
        <main>
            <router-view></router-view>
        </main>
        <!-- <OverlayMenu /> -->
        <BaseFooter
            class="base-footer"
            :footer-logo="footerLogo"
            :social-icons="socialIcons"
            :footer-columns="footerNavigationColumns"
        />
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import BaseFooter from "@/components/BaseFooter";
import VueHeap from "vue-heap";
import Vue from 'vue';

export default {
    name: "App",
    components: {
        NavBar,
        BaseFooter,
    },

    data() {
        return {
            footerLogo: {},
            socialIcons: {},
            footerNavigationColumns: []
        };
    },
    mounted() {
        this.watchTrigger();
        const options = { key : process.env.VUE_APP_HEAP_APP_ID, enable: process.env.VUE_APP_HEAP_ENABLED }
        VueHeap.install(Vue, options)
    },
    methods: {

        getContentful: function() {
            this.$contentful
                .getEntries({
                    content_type: "sectionGlobalFooter",
                    include: 3,
                    resolveLinks: true,
                    limit: 1
                })
                .then(entries => {
                    const data = entries.items[0];
                    this.footerLogo = data.fields.footerLogo;
                    this.socialIcons = data.fields.socialIcons;
                    this.footerNavigationColumns =
                        data.fields.footerNavigationColumns;
                })
                .catch(err => console.log(err));
        },
        watchTrigger: function() {
            const ref = this;
            const targetElement = document.querySelector(".page-exit-ramp");
            if ("IntersectionObserver" in window && targetElement) {
                const observer = new IntersectionObserver(function(changes) {
                    changes.forEach(function(change) {
                        if (change.isIntersecting) {
                            ref.getContentful();
                            observer.unobserve(targetElement);
                        }
                    });
                });
                observer.observe(targetElement);
            } else {
                console.log("Target does not exist, loading footer...");
                ref.getContentful();
                return;
            }
        }
    }
};
</script>

<style>
#app {
    height: 100vh;
    overflow-x: hidden;
}
#nav {
    background-color: #fff !important;
}
</style>
