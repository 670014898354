var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "base-footer px-6 py-10" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        {
          staticClass:
            "column is-one-quarter-tablet is-one-quarter-desktop is-half-fullhd mb-10",
        },
        [
          _vm.imageData
            ? _c("Logo", {
                staticClass: "sdc-logo",
                attrs: {
                  "data-testid": "footer-logo",
                  "image-path": _vm.imageData.file.url,
                  width: 73,
                  "alt-text": _vm.imageData.description,
                  url: _vm.sdcUrl,
                },
              })
            : _vm._e(),
          _vm.socialData
            ? _c("BaseSocialCollection", {
                staticClass: "is-flex mt-5 is-hidden-mobile",
                attrs: {
                  "data-testid": "footer-desktop-social-links",
                  icons: _vm.socialData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "div",
            {
              staticClass: "columns",
              attrs: { "data-testid": "footer-nav-links" },
            },
            _vm._l(_vm.footerColumns, function (column, index) {
              return _c("BaseNavCollection", {
                key: column.sys.id,
                attrs: {
                  "is-first": index === 0,
                  "nav-heading": column.fields.navHeading,
                  "nav-items": column.fields.navItems,
                },
                on: { openAccessibe: _vm.displayAccessibe },
              })
            }),
            1
          ),
          _vm.socialData
            ? _c("BaseSocialCollection", {
                staticClass: "is-flex is-hidden-tablet",
                attrs: {
                  "data-testid": "footer-mobile-social-links",
                  icons: _vm.socialData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }