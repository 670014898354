var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "column is-flex is-flex-direction-column mb-5-mobile-only",
      class: {
        "is-flex-shrink-0 is-flex-grow-2": _vm.isFirst,
        "is-flex-grow-1": !_vm.isFirst,
      },
    },
    [
      _c("h2", { staticClass: "title is-6 mb-3" }, [
        _vm._v(_vm._s(_vm.navHeading)),
      ]),
      !_vm.loading
        ? _c(
            "ul",
            _vm._l(_vm.navItems, function (nav) {
              return _c("BaseNavItem", {
                key: nav.sys.id,
                attrs: {
                  "display-name": nav.fields.navItemDisplayName,
                  link: nav.fields.navItemLink,
                  target: nav.fields.navItemLinkTarget,
                  "phone-number-display": nav.fields.phoneNumberDisplay,
                  "phone-number-configuration":
                    nav.fields.phoneNumberConfiguration,
                  "nav-item-alternate-function":
                    nav.fields.navItemAlternateFunction,
                },
                on: { alternateNavEvent: _vm.fireAccessibe },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.isSocial && _vm.isSocial.length > 0
        ? _c(
            "div",
            { staticClass: "column is-full social-icons" },
            _vm._l(_vm.isSocial, function (social) {
              return _c("BaseSocialCollection", {
                key: social.sys.id,
                attrs: { "collection-id": social.sys.id },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }