const providerConstants = {
  id: '',
  providerId: '',
  displayName: '',
  name: '',
  locations: [
    {
      id: '',
      institutionId: '',
      streetAddress: '',
      streetAddress2:null,
      city: '',
      state: '',
      zipCode: '',
      name: '',
      latitude: '',
      longitude: '',
      phoneNumber: '',
      tz: '',
      workingHours: []
    }
  ]
}
const bookedInfoConstants = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  dateOfBirth: null,
  gender: null,
  providerId: null,
  locationId: null,
  startTime: null,
  endTime: null,
  operatoryId: null
}

const faqConstants = [{
    question: 'How should I prepare for my appointment?',
    response: "This is your first step to getting a smile you'll love. During your in-person visit, you'll meet your local dentist and staff, who will take a 3D scan or impression of your current smile, talk you through your treatment options, and discuss how to best achieve your smile transformation goals. Finally, they'll send your information to a state-licensed dentist or orthodontist in our network, who will oversee your new treatment plan. The entire visit takes just 30 minutes."
  },{
    question: 'What will happen at my appointment?',
    response: "You'll begin with an in-person visit at a participating local dentist in our network. They'll look at your overall dental health, do a 3D scan or impression of your teeth, and answer any questions you might have before you start your Smile Journey™. Your information is sent to SmileDirectClub where a licensed dentist or orthodontist will use it to review and approve your Smile Prescription™."
  },{
    question: 'Will I need to pay anything?',
    response: "No, the appointment itself is completely free. If you decide to place your order during the appointment, we’ll then make your custom aligners and ship them direct to you. When you place your order, you get two easy payment options. Choose what works best for you. With Flex Pay, enjoy convenient monthly payments. Or pay all at once and save with our Single Pay option."
  },{
    question: 'How old do you have to be to start SDC?',
    response: "Our minimum age requirement is 12 years old, and patients must have all permanent teeth present. For patients under the age of 18, we need a parent/guardian signature on the Medical/Dental History Form provided during the evaluation step."
  },{
    question: 'When will I see my treatment plan?',
    response: "If you are eligible for treatment, you should receive a plan within 72 hours of your visit."
  }
]

const providerLogosConstants = {
  profilePictureUrl: '',
  practicePictureUrl: ''
}

const appointmentTypes = ["initial", "check-in", "care-plus-initial"]

export {
  providerConstants,
  faqConstants,
  bookedInfoConstants,
  providerLogosConstants,
  appointmentTypes
}
