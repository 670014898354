<template>
    <footer class="base-footer px-6 py-10">
        <div class="columns">
            <!-- Logo and Socials -->
            <div
                class="column is-one-quarter-tablet is-one-quarter-desktop is-half-fullhd mb-10"
            >
                <Logo
                    data-testid="footer-logo"
                    v-if="imageData"
                    :image-path="imageData.file.url"
                    :width="73"
                    class="sdc-logo"
                    :alt-text="imageData.description"
                    :url="sdcUrl"
                />
                <BaseSocialCollection
                    data-testid="footer-desktop-social-links"
                    v-if="socialData"
                    class="is-flex mt-5 is-hidden-mobile"
                    :icons="socialData"
                />
            </div>
            <div class="column">
                <div class="columns"
                    data-testid="footer-nav-links">
                    <BaseNavCollection
                        v-for="(column, index) in footerColumns"
                        :key="column.sys.id"
                        :is-first="index === 0"
                        :nav-heading="column.fields.navHeading"
                        :nav-items="column.fields.navItems"
                        @openAccessibe="displayAccessibe"
                    />
                </div>

                <BaseSocialCollection
                    data-testid="footer-mobile-social-links"
                    v-if="socialData"
                    class="is-flex is-hidden-tablet"
                    :icons="socialData"
                />
            </div>
        </div>
    </footer>
</template>

<style
    scoped
    src="../../node_modules/@smiledirectclub/bulma-based-components/dist/sdc-base.css"
></style>

<script>
import BaseNavCollection from "./BaseNavCollection";

const Logo = () => import("./Logo");
const BaseSocialCollection = () => import("./BaseSocialCollection");

export default {
    name: "BaseFooter",
    components: {
        Logo,
        BaseNavCollection,
        BaseSocialCollection
    },
    data() {
        return {
            sdcUrl: process.env.VUE_APP_SDC_ECOMMERCE_BASE_URL
        };
    },
    props: {
        footerLogo: {
            type: Object,
            required: true
        },
        footerColumns: {
            type: Array,
            required: true
        },
        socialIcons: {
            type: Object,
            required: true
        }
    },
    computed: {
        imageData() {
            return this.footerLogo.fields;
        },
        socialData() {
            return this.socialIcons.fields;
        }
    },
    methods: {
        displayAccessibe() {
            const accwidget = document.querySelector("div.acsb-trigger");
            if (!accwidget) {
                // eslint-disable-next-line no-console
                console.error("Error: No accessiBE widget found");
            } else {
                console.log("Found accessiBE widget");
                accwidget.style.visibility = "visible";
                // Create our event
                const evt = new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window
                });
                // Put it all together
                accwidget.dispatchEvent(evt);
                // eslint-disable-next-line no-console
                console.log("Opened accessiBE widget");
            }
        }
    }
};
</script>
