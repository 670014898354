/* istanbul ignore file */
import axios from 'axios'
import Vue from 'vue';
import "vue-toastification/dist/index.css";
import { TYPE } from "vue-toastification";

//TODO: Make the baseUrl configurable
const getProviderProxyInstance = baseURL => (
  axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 60000
  })
)
const providerProxyInstance = getProviderProxyInstance(process.env.VUE_APP_PROVIDER_PROXY_API_BASE_URL)
const providerProxyNon404Instance = getProviderProxyInstance(process.env.VUE_APP_PROVIDER_PROXY_API_BASE_URL)

function handleResponse(response) {
  // all 2xx/3xx responses will end here
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}

function handleError(error, redirect=false, autoclose=true, msgType=TYPE.ERROR) {
  if (error.response && error.response.data && error.response.data.message) {
    Vue.$toast(`${error.response.data.message}`,
        {
            onClose: redirect ? redirectToSmileshops : undefined ,
            timeout:  autoclose ? undefined : false,
            type: msgType
        });
  } else {
    Vue.$toast.error(`${error}`, { onClose: redirect ? redirectToSmileshops : undefined });
  }

  return Promise.reject(error);
}

providerProxyInstance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    var redirect = true
    var messagetype = TYPE.ERROR
    var autoclose = true

    let nonRedirectingMessageList = [
      'Time slot is not available, please choose another time',
      'A patient record with the given information has been found under a different email address.',
      'Appointment data for this patient is currently being processed. Please call the provider directly or try again shortly to book an appointment.',
      'Invalid email address'
    ]

    let handleDisplayMessages = [
        {
            responseMessage: 'DISALLOW_EXISTING_PATIENT',
            displayMessage:  'Welcome Back! Looks like we already know you. ' +
                             'If you have questions about an upcoming appointment or need to schedule an additional appointment with SmileDirectClub, ' +
                             'please contact our Customer Care Team at (800) 848-7566',
            messageType: TYPE.INFO,
            autoclose: false
        }
    ]

    if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined ) {
        if(nonRedirectingMessageList.some(str => error.response.data.message.includes(str)))
            redirect = false

        if(handleDisplayMessages.some(msg => error.response.data.message.includes(msg.responseMessage)))
        {
            let msg = handleDisplayMessages.find(str => error.response.data.message.includes(str.responseMessage))
            redirect = false
            error.response.data.message = msg.displayMessage
            messagetype = msg.messageType
            autoclose = msg.autoclose
        }
    }

    return handleError(error, redirect, autoclose, messagetype);
  }
);

providerProxyNon404Instance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    if (error.response.status === 404)
      return Promise.resolve(error.response);
    return handleError(error);
  }
);

function redirectToSmileshops() {
  window.location.href = `${process.env.VUE_APP_SDC_ECOMMERCE_BASE_URL}smileshops/`;
}

export {
  providerProxyInstance,
  providerProxyNon404Instance
}
