import Vue from 'vue'
import VueRouter from 'vue-router'
import { setMetaTitles } from '_utils_/routeGuards'

Vue.use(VueRouter)

function mapRouteParameters (route) {
    return {
        providerId: route.params.id || route.query.providerId || '',
        contactKey: route.params.contactKey || route.query.contactKey || route.query.utm_term || '',
        vulcanStoreNumber: route.query.sn || '',
        locationUUID: route.query.location || '',
        utmTerm: route.query.utm_term  || '',
        utmSource: route.query.utm_source || '',
        utmMedium: route.query.utm_medium || '',
        utmContent: route.query.utm_content || '',
        utmCampaign: route.query.utm_campaign || '',
        appointmentType: route.query.type || ''
    }
}

const routes = [
  {
    path: '/appointment/confirmed',
    name: 'confirmation',
    component: () =>
      import(/* webpackChunkName: "confirmation" */ "_views_/ConfirmationDetail"),
    meta: {
      title: "SDC Partner Booking | Confirmed"
    }
  },
  {
    path: '/appointment',
    name: 'appointmentWithQueryParams',
    component: () =>
      import(/* webpackChunkName: "home" */ "_views_/Home"),
    props: mapRouteParameters,
    meta: {
      title: "SDC Partner Booking | Appointment"
    }
  },
  {
    path: '/appointment/:id',
    name: 'appointment',
    component: () =>
      import(/* webpackChunkName: "home" */ "_views_/Home"),
    props: mapRouteParameters,
    meta: {
      title: "SDC Partner Booking | Appointment"
    }
  },
  {
    path: '/appointment/:id/:contactKey',
    name: 'appointmentWithContactKey',
    component: () =>
      import(/* webpackChunkName: "home" */ "_views_/Home"),
    props: mapRouteParameters,
    meta: {
      title: "SDC Partner Booking | Appointment"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    document.getElementById('nav').scrollIntoView();
}
})

router.beforeEach(setMetaTitles)

export default router
